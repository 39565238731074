<template>

</template>

<script>
export default {
  name: "GeneralSecurityTips",
  async beforeMount(){
    if(this.UserRole === 'investor'){
      await this.$router.replace({name: 'seguridadInversionistas'})
    }
    else if(this.UserRole === 'supplier' || this.UserRole === 'agent' || this.UserRole === 'supplier_agent'){
      await this.$router.replace({name: 'seguridadInstaladores'})
    }
    else {
      await this.$router.replace({name: 'home'})
    }
  }
}
</script>

<style scoped>

</style>